import ButtonText from 'components/ButtonText/ButtonText';
import { SlidesObject } from 'components/Slider/SliderEmbla/SliderEmbla.styled';
import { BREAKPOINTS } from 'constants/breakpoints';
import { ARROW_POSITIONS } from 'constants/generic';
import { ICON_NAMES } from 'constants/icon';
import { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel';
import { useElementViewed } from 'hooks/useElementViewed';
import useRouter from 'hooks/useRouter';
import useTrackingEvents from 'hooks/useTrackingEvents';
import { ReactNode, useRef, useState } from 'react';
import { isBanner } from 'types/Contentful';
import { IBanner, IBannerCarousel, IBlockImage } from 'types/ContentfulTypes';
import { Button } from 'ui/Button/Button';
import { Image } from 'ui/Image/Image';
import { cn } from 'utils/cn';
import ContentfulPageLink from '../../ContentfulPageLink/ContentfulPageLink';
import { NavigationItemWrapper, StyledBannerCarousel, StyledSlider } from './ContentfulBannerCarouselWide.styled';

const useBannerTracking = (bannerInfo: IBanner) => {
  const { onComponentClick, onComponentView } = useTrackingEvents();
  const { asPath: path, locale } = useRouter();
  const banner = bannerInfo.fields;
  const campaignId = banner.internalLabel ?? bannerInfo.sys.id;
  const component = 'ContentfulBannerCarousel';
  const campaign = [component, campaignId, locale].filter(Boolean).join('-');

  const { ref } = useElementViewed(() => {
    onComponentView({
      campaign,
      component,
      path,
    });
  });

  const onClick = () => {
    onComponentClick({
      campaign,
      component,
      path,
    });
  };

  return { onClick, ref };
};

const ImageLinkWrapper = ({
  children,
  className,
  image,
}: {
  children: ReactNode;
  className?: string;
  image: IBlockImage;
}) => {
  const link = image?.fields?.link;
  if (!link) return <div className={className}>{children}</div>;

  return (
    <ContentfulPageLink className={className} link={link}>
      {children}
    </ContentfulPageLink>
  );
};

const BannerCarouselItem = ({ bannerInfo, index }: { bannerInfo: IBanner; index: number }) => {
  const { onClick, ref } = useBannerTracking(bannerInfo);
  const banner = bannerInfo.fields;
  const bannerAltText = [banner.title, banner.copy].filter(Boolean).join(' ');
  const bannerImageMobileUrl = banner.mobileImage?.fields.image.fields.file?.url;
  const bannerImageMediumUrl = banner.mediumImage?.fields.image.fields.file?.url;
  const bannerImageWideUrl = banner.wideImage?.fields.image.fields.file?.url;
  const buttonText = banner.button?.fields?.label;

  const button = (
    <div className="absolute bottom-4 right-4">
      <Button
        variant="primary"
        className="px-3 py-1"
        icon={{ name: ICON_NAMES.ANGLE_RIGHT, styling: 'far' }}
        iconPosition="after"
      >
        {banner.button?.fields?.label}
      </Button>
    </div>
  );

  const imageWrapperClass = 'aspect-banner-homepage relative w-full overflow-hidden';

  return (
    <div key={`cf-banner-carousel-wide-${buttonText}-${index}`} ref={ref} onClick={onClick}>
      {bannerImageMobileUrl && (
        <ImageLinkWrapper
          className={cn('box-content block rounded-lg border md:hidden', imageWrapperClass)}
          image={banner.mobileImage}
        >
          <Image
            src={bannerImageMobileUrl}
            alt={bannerAltText}
            priority={index === 0}
            fill
            useNext
            nextSizes={{
              default: 350,
            }}
            style={{
              objectFit: 'contain',
            }}
          />
        </ImageLinkWrapper>
      )}
      {bannerImageMediumUrl && (
        <ImageLinkWrapper
          className={cn('hidden rounded-t-lg md:block lg:hidden', imageWrapperClass)}
          image={banner.mediumImage}
        >
          <Image
            src={bannerImageMediumUrl}
            alt={bannerAltText}
            priority={index === 0}
            fill
            useNext
            nextSizes={{
              default: 1000,
            }}
            style={{
              objectFit: 'contain',
            }}
          />
          {buttonText && button}
        </ImageLinkWrapper>
      )}
      {bannerImageWideUrl && (
        <ImageLinkWrapper className={cn('hidden rounded-t-lg lg:block', imageWrapperClass)} image={banner.wideImage}>
          <Image
            src={bannerImageWideUrl}
            alt={bannerAltText}
            priority={index === 0}
            fill
            useNext
            nextSizes={{
              default: 1600,
            }}
            style={{
              objectFit: 'contain',
            }}
          />
          {buttonText && button}
        </ImageLinkWrapper>
      )}
    </div>
  );
};

type ContentfulBannerCarouselWideProps = {
  className?: string;
  content: IBannerCarousel;
  priority?: boolean;
};

const ContentfulBannerCarouselWide = ({ content }: ContentfulBannerCarouselWideProps) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const slider = useRef<EmblaCarouselType>(null);

  const fields = content?.fields;

  if (!fields) {
    return null;
  }

  const { items: unfilteredItems } = fields;

  const items = unfilteredItems?.filter((item) => !!item?.fields);

  const onSlideChange = (newIndex: number) => {
    setActiveSlide(newIndex);
  };

  const goToSlide = (index: number) => {
    if (slider.current) {
      slider.current.scrollTo(index);
    }
  };

  const options: EmblaOptionsType = {
    loop: true,
  };
  const slidesToShow: SlidesObject = {
    [BREAKPOINTS.XS]: 1,
    [BREAKPOINTS.SM]: 1,
    [BREAKPOINTS.MD]: 1,
    [BREAKPOINTS.LG]: 1,
    [BREAKPOINTS.XL]: 1,
    [BREAKPOINTS.XXL]: 1,
  };

  return (
    <StyledBannerCarousel className="rounded-lg">
      <StyledSlider
        showArrows={{ [BREAKPOINTS.MD]: true }}
        showProgressBar
        arrowPosition={ARROW_POSITIONS.CENTER}
        showArrowsOnHover={true}
        ref={slider}
        options={options}
        onScrollCallback={onSlideChange}
        autoplay
        slidesToShow={slidesToShow}
        progressBarClassName="md:hidden"
      >
        {items?.map((item, index) => {
          const banner = item?.fields?.content;
          if (!isBanner(banner)) {
            return null;
          }
          return <BannerCarouselItem key={item?.sys?.id} index={index} bannerInfo={banner} />;
        })}
      </StyledSlider>

      {!!items?.length && (
        <>
          <NavigationItemWrapper>
            {items?.map((item, index) => {
              const isActive = index === activeSlide;

              if (!item?.fields?.title) {
                return null;
              }

              return (
                <ButtonText
                  key={item?.sys?.id}
                  onClick={() => goToSlide(index)}
                  color={isActive ? 'black' : 'whisper-300'}
                  colorOnHover="black"
                  fontWeight={isActive ? 'bold' : 'normal'}
                  fontSize={88}
                >
                  {item?.fields?.title}
                </ButtonText>
              );
            })}
          </NavigationItemWrapper>
        </>
      )}
    </StyledBannerCarousel>
  );
};

export default ContentfulBannerCarouselWide;
